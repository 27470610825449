import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const ContactHero = ({ data: { heading, image, image_alt } }) => {
  return (
    <section className="container mx-auto flex flex-col px-4 md:px-10 py-4 md:py-8">
      <Img
        className="mx-auto mb-8"
        fixed={image.childImageSharp.fixed}
        alt={image_alt}
      />
      <h1 className="text-2xl md:text-4xl font-medium text-center leading-tight mx-auto mb-8">
        {heading}
      </h1>
    </section>
  )
}

export default ContactHero
