import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactHero from "../components/contact/contactHero"
import ContactForm from "../components/contact/contactForm"

export const ContactPageTemplate = ({
  data: {
    markdownRemark: {
      frontmatter: { slug, title, description, hero },
    },
  },
  location,
}) => {
  return (
    <Layout location={location}>
      <SEO title={title} description={description} pathname={slug} />
      <ContactHero data={hero} />
      <ContactForm />
    </Layout>
  )
}

export default ContactPageTemplate

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        slug
        title
        description
        hero {
          heading
          image {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          image_alt
        }
      }
    }
  }
`
